import axios from "axios";
import { handleError } from "@/utils/error-handling";

export const state = {
  adminPanels: [],
  adminPanelPagination: {},
  companies: [],
  companyPagination: {},
  pendingGroupOwnerships: [],

  isLoadingOrganizations: false,
  hasLoadedOrganizations: false,
};

export const getters = {
  getAdminPanels: (state) => state.adminPanels,
  getAdminPanelPagination: (state) => state.getAdminPanelPagination,
  getCompanies: (state) => state.companies,
  getCompanyPagination: (state) => state.companyPagination,
  getPendingGroupOwnerships: (state) => state.pendingGroupOwnerships,
  isLoadingOrganizations: (state) => state.isLoadingOrganizations,
  hasLoadedOrganizations: (state) => state.hasLoadedOrganizations,
};

export const actions = {
  async fetchPendingGroupOwnerships(context) {
    try {
      const { status, data } = await axios.get(
        "/users/switcher/pending_group_ownerships"
      );

      // Will return 204 no content if not onboarded yet
      if (status !== 204) {
        context.commit(
          "setPendingGroupOwnerships",
          data.pending_group_ownerships
        );
      }
    } catch (error) {
      handleError(error);
    }
  },

  async fetchOrganizations(context, query) {
    context.commit("setIsLoadingOrganizations", true);
    try {
      const { data } = await axios.get("/users/switcher", {
        params: {
          query: query,
        },
      });

      context.commit("setAdminPanels", {
        adminPanels: data.admin_panels,
        pagination: data.admin_panel_pagination,
      });
      context.commit("setCompanies", {
        companies: data.companies,
        pagination: data.company_pagination,
      });
      context.commit("setHasLoadedOrganizations", true);
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("setIsLoadingOrganizations", false);
    }
  },
};

export const mutations = {
  setAdminPanels(state, { adminPanels, pagination }) {
    state.adminPanels = adminPanels;
    state.adminPanelPagination = pagination;
  },

  setCompanies(state, { companies, pagination }) {
    state.companies = companies;
    state.companyPagination = pagination;
  },

  setPendingGroupOwnerships(state, pendingGroupOwnerships) {
    state.pendingGroupOwnerships = pendingGroupOwnerships;
  },

  setIsLoadingOrganizations(state, isLoadingOrganizations) {
    state.isLoadingOrganizations = isLoadingOrganizations;
  },

  setHasLoadedOrganizations(state, hasLoadedOrganizations) {
    state.hasLoadedOrganizations = hasLoadedOrganizations;
  },
};

export default {
  namespaced: true,
  sessionCached: true,
  state,
  getters,
  actions,
  mutations,
};
