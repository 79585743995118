<template>
  <div>
    <h5>
      {{
        $t(
          `components.meetings.material.preview.${translationScope}.minutes_title`,
          company.locale
        )
      }}
    </h5>

    <div v-if="attendancesAttending.length > 0">
      <template v-for="attendance in attendancesAttending" :key="attendance.id">
        <p
          class="m-0"
          :class="{
            'text-reset-line-height mt-1': attendance.reported_absence,
          }"
        >
          {{ attendance.name }}

          <small v-if="attendance.attending === 'attending_online'">
            {{
              $t(
                "views.companies.meetings.materials.invitations.video",
                company.locale
              )
            }}
          </small>
        </p>

        <small
          v-if="attendance.reported_absence"
          :key="`${attendance.id}-reported_absence`"
        >
          {{
            $t(
              "views.companies.meetings.materials.invitations.reported_absence",
              company.locale
            )
          }}
        </small>
      </template>
    </div>
  </div>
</template>

<script>
import materialType from "./materialState";
import { mapGetters } from "vuex";

export default {
  mixins: [materialType],

  props: {
    selectedAttendances: {
      type: Array,
      required: true,
    },

    translationScope: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),

    attendancesAttending() {
      return this.selectedAttendances.filter((attendance) => {
        return ["attending_irl", "attending_online"].includes(
          attendance.attending
        );
      });
    },
  },
};
</script>
