<template>
  <div>
    <h3 class="mb-3">
      {{ $t("activerecord.attributes.attendance.present", company.locale) }}
    </h3>

    <div class="row">
      <div
        v-if="regularsAndChairman.length"
        class="col-12 mb-3 mb-md-0"
        :class="
          absent.length > 0 ? 'col-md-3 col-print-3' : 'col-md-4 col-print-4'
        "
      >
        <minutes-attendance-column
          :selected-attendances="regularsAndChairman"
          translation-scope="attendances"
        />

        <h5 v-if="attendancesWithNotes.length > 0" class="mt-3">
          {{
            $t(
              "views.companies.meetings.materials.attendances.notes",
              company.locale
            )
          }}
        </h5>

        <div
          v-for="attendance in attendancesWithNotes"
          :key="`attendence-notes-${attendance.id}`"
          class="mb-2"
        >
          <h6 class="mb-0">
            {{ attendance.name }}
          </h6>

          <p v-dompurify-html="attendance.notes" />
        </div>
      </div>

      <div
        v-if="alternate.length"
        class="col-12 mb-3 mb-md-0"
        :class="
          absent.length > 0 ? 'col-md-3 col-print-3' : 'col-md-4 col-print-4'
        "
      >
        <minutes-attendance-column
          :selected-attendances="alternate"
          translation-scope="alternate"
        />
      </div>

      <div
        v-if="externals.length"
        class="col-12 mb-3 mb-md-0"
        :class="
          absent.length > 0 ? 'col-md-3 col-print-3' : 'col-md-4 col-print-4'
        "
      >
        <minutes-attendance-column
          :selected-attendances="externals"
          translation-scope="externals"
        />
      </div>

      <div v-if="absent.length > 0" class="col-12 col-md-3 col-print-3">
        <h5>
          {{
            $t(
              `components.meetings.material.preview.absent.title`,
              company.locale
            )
          }}
        </h5>

        <div v-if="absent.length > 0">
          <template v-for="attendance in absent" :key="attendance.id">
            <p
              class="m-0"
              :class="{
                'text-reset-line-height mt-1': attendance.reported_absence,
              }"
            >
              {{ attendance.name }}

              <small v-if="attendance.attending === 'attending_online'">
                {{
                  $t(
                    "views.companies.meetings.materials.invitations.video",
                    company.locale
                  )
                }}
              </small>
            </p>

            <small
              v-if="attendance.reported_absence"
              :key="`${attendance.id}-reported_absence`"
            >
              {{
                $t(
                  "views.companies.meetings.materials.invitations.reported_absence",
                  company.locale
                )
              }}
            </small>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MinutesAttendanceColumn from "./MinutesAttendanceColumn.vue";

export default {
  components: {
    MinutesAttendanceColumn,
  },

  computed: {
    ...mapGetters("material", ["items", "loading", "material", "formInvalid"]),

    ...mapGetters({
      attachments: "material/attachments",
      attendances: "attendances/attendances",
      company: "company/getCompany",
      meeting: "material/meeting",
      users: "company/users",
    }),

    attendancesWithNotes() {
      return this.attendances.filter((attendance) => attendance.notes);
    },

    regularsAndChairman() {
      return this.attendances.filter((attendance) => {
        return ["chairman", "regular"].includes(attendance.function);
      });
    },

    absent() {
      return this.attendances.filter((attendance) => {
        return ["absent"].includes(attendance.attending);
      });
    },

    alternate() {
      return this.attendances.filter((attendance) => {
        return ["alternate"].includes(attendance.function);
      });
    },

    externals() {
      return this.attendances.filter((attendance) => {
        return ["external"].includes(attendance.function);
      });
    },
  },
};
</script>
