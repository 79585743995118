<template>
  <div v-if="showButtons">
    <be-button
      v-if="document.signed_at"
      :href="url(`documents/${document.id}?disposition=inline`)"
      variant="outline-primary"
      size="sm"
      class="ml-auto"
      target="_blank"
    >
      {{ $t("companies.documents.document_options.open_pdf") }}
    </be-button>

    <be-dropdown v-else-if="document.meeting_information" ellipsis>
      <be-dropdown-item :href="document.meeting_information.path">
        {{ $t("models.meeting.show_meeting") }}
      </be-dropdown-item>
    </be-dropdown>

    <be-dropdown v-else-if="canRemoveSignatureCase || !rejected" ellipsis>
      <be-dropdown-group>
        <be-dropdown-item
          v-if="!rejected"
          v-be-tooltip="{
            title: $t(
              'components.documents.signature_case.update_status_tooltip'
            ),
            disabled: updating,
          }"
          :disabled="updating"
          @click="signatureStatusUpdateRequested"
        >
          <be-spinner v-if="updating">
            {{ $t("components.documents.signature_case.updating") }}
          </be-spinner>

          <template v-else>
            {{ $t("components.documents.signature_case.status_update_button") }}
          </template>
        </be-dropdown-item>
      </be-dropdown-group>

      <be-dropdown-group>
        <be-dropdown-item
          v-if="canRemoveSignatureCase"
          :href="url(`documents/signatures/${localDocument.id}`)"
          variant="danger"
          data-method="delete"
          :data-confirm="
            $t('components.documents.signature_case.confirm_cancel_w_title', {
              title: localDocument.filename,
            })
          "
          :data-description="
            $t('components.documents.signature_case.confirm_remove_description')
          "
          :data-cancel-button-text="$t('buttons.titles.keep')"
          :data-confirm-button-text="
            $t('components.documents.signature_case.cancel_request')
          "
        >
          {{ $t("components.documents.signature_case.cancel_request") }}
        </be-dropdown-item>
      </be-dropdown-group>
    </be-dropdown>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localDocument: this.cloneDeep(this.document),
      updating: false,
      updateInterval: null,
      signaturesJobCallback: null,
    };
  },

  computed: {
    showButtons() {
      return (
        this.canRemoveSignatureCase ||
        this.document.signed_at ||
        this.document.meeting_information
      );
    },

    canRemoveSignatureCase() {
      return (
        !this.localDocument.signed_at &
        (this.checkPolicy("abort_signature_request") ||
          this.localDocument.policy?.destroy)
      );
    },

    rejected() {
      return (
        this.localDocument.signature_status === "rejected" ||
        this.localDocument.signatures.some((signature) => signature.rejected_at)
      );
    },
  },

  methods: {
    async signatureStatusUpdateRequested() {
      try {
        this.updating = true;
        const { data } = await axios.post(
          this.url(
            `/documents/${this.localDocument.id}/signature_request/statuses`
          )
        );
        EventBus.emit("new-notification", {
          message: this.$t(
            "components.documents.signature_case.status_update_requested"
          ),
        });
        this.signaturesJobCallback = data;
        this.startPolling();
      } catch (error) {
        this.handleError(error);
        this.updating = false;
        this.signaturesJobCallback = null;
      }
    },

    async fetchCheckStatus() {
      if (this.signaturesJobCallback == null) {
        return;
      }
      try {
        const { data } = await axios.get(
          this.url(
            `/documents/${this.localDocument.id}/signature_request/statuses/${this.signaturesJobCallback.id}`
          )
        );
        this.signaturesJobCallback = data;
        if (data.status === "completed") {
          this.stopPolling();
        } else if (data.status === "failed") {
          this.stopPolling();
          EventBus.emit("new-notification", {
            status: "danger",

            message: this.$t(
              "components.documents.signature_case.status_update_failed"
            ),
          });
        }
      } catch (error) {
        this.handleError(error);
        this.stopPolling();
      }
    },

    startPolling() {
      if (this.updateInterval == null) {
        this.updateInterval = setInterval(() => {
          this.fetchCheckStatus();
        }, 3000);
      }
    },

    stopPolling() {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
      this.updating = false;
    },
  },
};
</script>
